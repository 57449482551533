import { default as Plugin } from 'models/modulor/web_module/content'

export class Content extends Plugin
  @defaults =
    debug: false
    name: 'TextModule__Content'

  @selector: ".text_module__content"

  on_init: ->
    @on_click_handler = @on_click.bind(this)
    @$element.on 'click', 'a', @on_click_handler

  on_destroy: ->
    @$element.off 'click', 'a', @on_click_handler

  on_click: (event) ->
    href = event.currentTarget.getAttribute('href')
    return unless href.startsWith('#')
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    return unless element = document.getElementById(href.slice(1))
    element.scrollIntoView({ behavior: 'smooth' })

Content.register()
